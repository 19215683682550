import React from 'react';
import './DriverBox.css';
/*
interface User {
  socket_id: string;
  name: string;
  type: number;
  host: boolean;
  roomid: string;
  canDrive: number;
  driving: string[];
}
*/

function DriverBox({ driver, getUserBySocketId, socket, setRequestSent, reqSent }) {
  const sendReq = () => {
    socket.emit('join-driver', {
      driverid: driver.socket_id,
      roomid: driver.roomid,
    });
    setRequestSent(true)
  };
  const kickRider = (riderid) => {
    socket.emit('kick-rider', {
      riderid,
      roomid: driver.roomid,
    });
  };
  const leaveDriver = () => {
    socket.emit('leave-driver', {
      driverid: driver.socket_id,
      roomid: driver.roomid,
    });
  };
  return (
    <div className='driver-box'>
      <div className='riders'>
        {Array(parseInt(driver.canDrive))
          .fill('a')
          .map((e, i) => {
            return driver.driving[i] ? (
              <p>
                {getUserBySocketId(driver.driving[i]) ? getUserBySocketId(driver.driving[i]).name : ''}{' '}
                {driver.socket_id === socket.id
                  ? '|' && (
                      <button onClick={() => kickRider(driver.driving[i])}>
                        Kick
                      </button>
                    )
                  : ''}
              </p>
            ) : (
              <p>OPEN</p>
            );
          })}
      </div>
      {getUserBySocketId(socket.id).type != 1 || reqSent ? (
        <div className='join'>
         {getUserBySocketId(socket.id).hasDriver !== driver.socket_id ? <button onClick={() => sendReq()}>Join</button> : <button onClick={() => leaveDriver()}>Leave</button>}
        </div>
      ) : (
        ''
      )}
      <div className='driver'>
        {driver.name}{' '}
        {driver.socket_id === socket.id
          ? '| You '
          : driver.driving.length + 1 + '/' + driver.canDrive}
      </div>
    </div>
  );
}

export default DriverBox;
