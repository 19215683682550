import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import logo from './logo.svg';
const firebaseConfig = {
  apiKey: "AIzaSyCqXzBaiMXSP_mOsfa_vzbNS7blfGrpfog",
  authDomain: "ride-along-20820.firebaseapp.com",
  projectId: "ride-along-20820",
  storageBucket: "ride-along-20820.appspot.com",
  messagingSenderId: "226004203403",
  appId: "1:226004203403:web:f09984a852595a7861a18e",
  measurementId: "G-D5N3F1XJ6X"
};
const root = ReactDOM.createRoot(document.getElementById('root'));
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
root.render(
  <React.StrictMode>
    
    <img className='logo' alt='' src={logo}></img>
    <App />
  </React.StrictMode>
);
