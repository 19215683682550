import './App.css';
import { useEffect, useState } from 'react';
import io from 'socket.io-client';
import Lobby from './Lobby';
const socket = io('https://ridealongapi.ryantanen.com', {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity,
});
let myID = socket.id;
const App = () => {
  const [codeIn, setCodeIn] = useState('');
  const [codeIn2, setCodeIn2] = useState(-1);
  const [code, setCode] = useState('');

  const [screen, setScreen] = useState(0);
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    socket.on('connect', () => {
      setIsConnected(true);
      myID = socket.id;
    });
    socket.on('disconnect', () => {
      setIsConnected(false);
    });
    socket.on('lobby-code', (data) => {
      setCode(data.roomid);
      setScreen(1);
    });
    socket.onAny((event, ...args) => {
      console.log(event, args);
    });
    // socket.on('join-req', (data) => {
    //   let rider;
    //   if (window.confirm(rider + ' would like to ride with you')) {
    //     socket.emit('accept-joinreq');
    //   }
    // });
    // socket.on('user-join', (data) => {
    //   const u = JSON.parse(data);
    //   setUsers(u.users);
    //   console.log('users', u.users);
    // });
    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('lobby-code');
    };
  }, []);
  const submitCode = (code) => {
    checkCode(code);
  };
  const submitHost = () => {
    createLobby();
  };
  const submitName = (name) => {
    sendName(name);
  };
  const submitType = async (type) => {
    sendType(type[0], type[1]);
  };
  const createLobby = () => {
    socket.emit('create-lobby');
  };
  const sendName = (name) => {
    socket.emit('name-select', { name });
    setScreen(2);
  };
  const sendType = (type, num) => {
    socket.emit('type-select', { type, canDrive: num });
    joinLobby();
  };
  const joinLobby = () => {
    socket.emit('join-lobby');
    setScreen(3);
  };
  const checkCode = (code) => {
    socket.emit('check-code', { lobby_code: code });
  };
  return (
    (isConnected && screen == 0 && (
      <div className='App'>
        <center>
          <h2 style={{ maxWidth: '550px', margin: '10px', fontSize: '1.3em' }}>
            {!code &&
              'Ride Along is a website designed to help individuals easily coordinate rides for events and activities, particularly within the BBYO DC Council.'}
          </h2>
          <h1>{code && code}</h1>
        </center>
        <label for='code'>Lobby Code</label>
        <br />
        <input
          type='text'
          id='code'
          name='code'
          onChange={(e) => setCodeIn(e.target.value.toUpperCase())}
        />
        <br />
        <input
          type='submit'
          value='Submit'
          onClick={() => submitCode(codeIn)}
        />
        <br></br>
        <label>Host a Lobby</label>
        <br></br>
        <input type='submit' value='Host' onClick={() => submitHost()} />
      </div>
    )) ||
    (screen == 1 && (
      <div className='App'>
        <center>
          <h1>{code && code}</h1>
        </center>
        <br></br>
        <label>Welcome, Whats your Name?</label>
        <br></br>
        <input
          type='text'
          id='code'
          name='code'
          onChange={(e) => setCodeIn(e.target.value)}
        />
        <br />
        <input type='submit' value='Go' onClick={() => submitName(codeIn)} />
      </div>
    )) ||
    (screen == 2 && (
      <div className='App'>
        <center>
          <h1>{code && code}</h1>
        </center>
        <br></br>
        <label>Are you a driver or a rider?</label>
        <br></br>
        <select name='DorR' onChange={(e) => setCodeIn(e.target.value)}>
          <option value='0'>--Please choose an option--</option>
          <option value='1'>Driver</option>
          <option value='2'>Rider</option>
        </select>
        <br />
        {codeIn == 1 && (
          <>
            <label>
              How many can you drive in your car including yourself?
            </label>
            <br></br>
            <select
              name='numCanDrive'
              onChange={(e) => setCodeIn2(e.target.value)}
            >
              <option value='0'>--Please choose an option--</option>
              <option value='1'>1</option>
              <option value='2'>2</option>
              <option value='3'>3</option>
              <option value='4'>4</option>
              <option value='5'>5</option>
              <option value='6'>6</option>
              <option value='7'>7</option>
              <option value='8'>8</option>
            </select>
          </>
        )}
        <br />
        <input
          type='submit'
          value='Go'
          onClick={() => submitType([codeIn, codeIn2])}
        />
      </div>
    )) ||
    (screen == 3 && (
      <div className='App'>
        <Lobby socket={socket} window={window} roomid={code} />
      </div>
    ))
  );
};

export default App;
