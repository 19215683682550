import React, { useEffect, useState } from 'react';
import DriverBox from './DriverBox';

const Lobby = ({ roomid, socket }) => {
  const [users, setUsers] = useState();
  const [requests, setRequests] = useState([]);
  const [reqSent, setRequestSent] = useState();
  const [self, setSelf] = useState();
  const getUserBySocketId = (socketId) => {
    console.log(socketId);
    if (!users || users.length === 0) {
      return null;
    }

    const user = users.find((obj) => obj.socket_id === socketId);
    if (!user) {
      return undefined;
    }

    return user;
  };
  useEffect(() => {
    socket.on('user-join', (data) => {
      const u = JSON.parse(data);
      setUsers(u.users);
      console.log('users', u.users);
    });
    socket.on('join-req', (data) => {
      let tmp = [...requests];
      tmp.push(data.rider);
      console.log('Hola', tmp);
      setRequests(tmp);
    });

    return () => {
      socket.off('user-join');
      socket.off('join-req');
    };
  }, []);
  const acceptRequest = (rider) => {
    socket.emit('accept-joinreq', {
      roomid,
      riderid: rider,
    });
    setRequests(requests.filter((obj) => obj != rider));
  };
  return (
    <div>
      {' '}
      <center>
        <h1 style={{ fontSize: '3em' }}>
          Join with:{' '}
          <code style={{ color: 'var(--primary)' }}>{roomid && roomid}</code>
        </h1>
        {}
      </center>
      <div className='phone-help'>Swipe to see more ➡</div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className='drivers-boxes'>
          {users &&
            users
              .filter((obj) => obj.type == 1)
              .map((element, i) => {
                return (
                  <DriverBox
                    driver={element}
                    socket={socket}
                    getUserBySocketId={getUserBySocketId}
                    setRequestSent={setRequestSent}
                    reqSent={reqSent}
                  />
                );
              })}
        </div>
        <br></br>
      </div>
      <div className='requests'>
        Looking for ride:{' '}
        <span>
          {users &&
            users
              .filter((obj) => !obj.hasDriver && obj.type == 2)
              .map((obj) => obj.name)
              .join(', ')}
        </span>
        <br />
        {requests &&
          requests.map((element, i) => {
            return (
              <div className='req'>
                <br></br>
                {getUserBySocketId(element).name}:
                <button onClick={() => acceptRequest(element)}>Accept</button>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Lobby;
